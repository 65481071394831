.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* 清除内外边距 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
/* structural elements 结构元素 */
dl,
dt,
dd,
ul,
ol,
li,
/* list elements 列表元素 */
pre,
/* text formatting elements 文本格式元素 */
fieldset,
lengend,
button,
input,
textarea,
/* form elements 表单元素 */
th,
td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 18px;
  /* 18px / 12px = 1.5 */
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

h4,
h5,
h6 {
  font-size: 100%;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

/* 将斜体扶正 */
code,
kbd,
pre,
samp,
tt {
  font-family: "PingFang SC", "Microsoft Yahei", "Source Han Sans CN";
}

/* 统一等宽字体 */
small {
  font-size: 12px;
}

/* 小于 12px 的中文很难阅读，让 small 正常化 */

/* 重置列表元素 */
ul,
ol {
  list-style: none;
}

/* 重置文本格式元素 */
a {
  text-decoration: none;
}

abbr[title],
acronym[title] {
  /* 注：1.ie6 不支持 abbr; 2.这里用了属性选择符，ie6 下无效果 */
  border-bottom: 1px dotted;
  cursor: help;
}

q:before,
q:after {
  content: "";
}

/* 重置表单元素 */
legend {
  color: #000;
}

/* for ie6 */
fieldset,
img {
  border: none;
}

/* img 搭车：让链接里的 img 无边框 */
/* 注：optgroup 无法扶正 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 使得表单元素在 ie 下能继承字体大小 */
}

/* 重置表格元素 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置 hr */
hr {
  border: none;
  height: 1px;
}

img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

input:focus,
button:focus {
  outline: none;
}

p,
li {
  font-family: "PingFang SC", "Microsoft Yahei", "Source Han Sans CN";
}


button {
  cursor: pointer;
}

.red {
  color: #e92d08 !important;
}

.yellow {
  color: #ff8b33;
}

.green {
  color: #28b068 !important;
}

.ant-table-cell {
  text-align: center;
}

/* tr .ant-table-cell:first-of-type{
  text-align: left !important;
} */
.ant-table-tbody>tr.ant-table-placeholder td {
  text-align: center;
  line-height: 300px !important;
  font-size: 15px;
  border: none;
}

.empty {
  text-align: center;
  line-height: 300px;
  font-size: 15px;
  border: none;
  color: rgba(0, 0, 0, 0.25);
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ant-modal .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.ant-modal .ant-modal-footer button {
  width: 90px;
}

.ant-modal .ant-modal-footer button:nth-of-type(2) {
  margin-left: 20px;
}

.ant-tooltip {
  width: 200px;
}

.ant-cascader-menu-item:hover {
  background: rgba(47, 83, 225, 0.06);
}

#root .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
#root .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  color: #2F53E1;
  font-weight: normal;
  background: rgba(47, 83, 225, 0.06);
}

#root .ant-pagination-item,
#root .ant-pagination-prev .ant-pagination-item-link,
#root .ant-pagination-next .ant-pagination-item-link,
#root .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-menu-submenu-arrow {
  z-index: 1;
}

#root .ant-btn {
  border-radius: 6px;
}

#root .ant-btn::after {
  display: none;
}

#root .ant-btn.fs-16 {
  font-size: 16px;
}

#root .ant-cascader-menu {
  height: 192px;
}

.ant-spin-text {
  color: #ccc;
}

.export-btn:hover {
  background-color: #e6e6e6 !important;
  border: 1px solid #CCCCCC !important;
}

.e_commerce {
  background: url("~@/assets/images/cloudChart/Competition/bj.png") no-repeat #0a183c;
}

.e_commerce h1 {
  background: url("~@/assets/images/cloudChart/Competition/header.png") no-repeat;
}